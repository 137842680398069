import { SplitTitle } from "../../Animations/SplitTitle"
import { gsap, DrawSVGPlugin, SplitText } from "gsap/all"
import lottie from "lottie-web"
gsap.registerPlugin(DrawSVGPlugin, SplitText)

export default class HeroFullWidthLottie {
  constructor() {
    this.DOM = { el: document.querySelector(".HeroFullWidthLottie") }
    this.DOM.title = this.DOM.el.querySelector(".js-title")
    this.DOM.subtitle = this.DOM.el.querySelector(".js-subtitle")
    this.DOM.desc = this.DOM.el.querySelector(".hfwl-desc")
    this.DOM.button = this.DOM.el.querySelector(".main-button")
    this.DOM.lottie = this.DOM.el.querySelector(".hfw-lottie")
    this.DOM.wave = this.DOM.el.querySelector(".wave3")
    this.DOM.illustrations = [...this.DOM.lottie.querySelectorAll(".lottie")]
    this.data = [
      [0, 11, "Good Morning"],          //Store messages in an array
      [12, 17, "Good Afternoon"],
      [18, 24, "Good Evening"]
  ]
  }

  create() {
    // change heading string to time of day eg 'good evening'

    if (this.DOM.title.classList.contains('js-time')) {
      const hr = new Date().getHours();
      for(var i = 0; i < this.data.length; i++){
        if(hr >= this.data[i][0] && hr <= this.data[i][1]){
            this.DOM.title.textContent = this.data[i][2]
        }

    }

  }

    this.title = SplitTitle(this.DOM.title)
      if (this.DOM.subtitle) {
        this.subtitle = SplitTitle(this.DOM.subtitle)
      }
    gsap.set([this.DOM.desc, this.DOM.button], { autoAlpha: 0 })



    // split text
    this.onResize()
  }

  chooseIllustration() {
    // loop through illustrations and set the illustration as a localstorage item
    if (localStorage.illustration) {
      let number = localStorage.illustration
      number < this.DOM.illustrations.length - 1 ? number++ : (number = 0)
      localStorage.setItem("illustration", number)
    } else {
      localStorage.setItem("illustration", 0)
    }

    // doesnt do anything but useful to know what illustrations visible
    this.DOM.illustrations[localStorage.illustration].classList.add("visible")

    lottie.loadAnimation({
      container: this.DOM.illustrations[localStorage.illustration], // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: this.DOM.illustrations[localStorage.illustration].dataset.lottie, // the path to the animation json
    })
  }

  getLottiePlacement() {
    this.DOM.illustrations.forEach(
      (illo) =>
        (illo.style.bottom = `${
          this.DOM.wave.getBoundingClientRect().height
        }px`)
    )
  }

  animate() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: "expo.out",
        duration: 0.8,
      },
    })

    this.tlHero
    .to([this.title], {
      yPercent: 0,
      duration: 0.8,
      stagger: "0.2",
    }, 0)

    if (this.subtitle) {
      this.tlHero
      .to([this.subtitle], {
        yPercent: 0,
        duration: 0.6,
        stagger: "0.2",
      }, 0)

    }


    this.tlHero  
            .to([this.DOM.desc, this.DOM.button], { autoAlpha: 1 })

  }

  onResize() {
    this.getLottiePlacement()
  }

  // Necessary to have, leave empty if not needed
  gutenberg() {
    this.getLottiePlacement()
    if (this.DOM.lottie) {
      this.chooseIllustration()
    }
  }

  onLoad() {
    this.animate()
    if (this.DOM.lottie) {
      this.chooseIllustration()
    }
  }
}
