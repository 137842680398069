import { gsap, ScrollTrigger } from "gsap/all"
gsap.registerPlugin(ScrollTrigger)
// core version + navigation, pagination modules:
import Swiper, { Scrollbar, Autoplay } from "swiper"
// configure Swiper to use modules
Swiper.use([Scrollbar, Autoplay])

export default class PostSlider {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.wrapper = this.DOM.el.querySelector(".swiper-wrapper")
    this.DOM.slider = this.DOM.el.querySelector(".swiper")
    this.DOM.scrollbar = this.DOM.el.querySelector(".swiper-scrollbar")
    this.DOM.buttonNext = this.DOM.el.querySelector(".swiper-button-next")
    this.DOM.buttonPrev = this.DOM.el.querySelector(".swiper-button-prev")
    this.init()
  }

  swiperInit() {
    this.swiper = new Swiper(this.DOM.slider, {
      slidesPerView: 3,
      spaceBetween: 5,
      grabCursor: true,
      updateOnImagesReady: true,
      loop: true,
      navigation: {
        nextEl: this.DOM.buttonNext,
        prevEl: this.DOM.buttonPrev,
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        // when window width is >= 640px
        1920: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    })
  }

  init() {
    this.swiperInit()
  }
  gutenberg() {
    if (window.acf) {
      this.init()
    }
  }
}
