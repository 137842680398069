import gsap from "gsap"

export default class Popup {
  constructor({ header }) {
    this.DOM = { el: header.DOM.el }
    this.DOM.fullScreen = header.DOM.fullScreenNav
    this.DOM.pageBackground = header.DOM.pageBackground
    this.DOM.toggleIcon = header.DOM.toggleIcon
    this.body = header.body
    this.html = document.querySelector("html")
    this.header = header

    // Modal
    this.DOM.modalButton = this.DOM.el.querySelector(".js-header-call-back")
    this.DOM.modal = document.querySelector(".js-contact-pop-up")
    this.DOM.modalClose = this.DOM.modal.querySelector(".js-close")
    this.DOM.modalID = this.DOM.modal.dataset.id

    // All links
    this.DOM.allLinks = [...document.querySelectorAll("a")]

    this.init()
  }

  init() {
    this.showPopup()
    this.eventListeners()
    gsap.set(this.DOM.modal, { yPercent: 20, xPercent: -50, autoAlpha: 0 })
  }

  showPopup() {
    // on page load
    const hash = window.location.hash

    // look for hash
    if (hash === `#${this.DOM.modalID}`) this.openModal()

    // on link click
    this.DOM.allLinks.forEach((link) => {
      link.addEventListener("click", (e) => {
        if (e.target.hash === `#${this.DOM.modalID}`) {
          e.preventDefault()
          this.openModal()
        }
      })
    })
  }

  openModal() {
    // animate in
    gsap.to(this.DOM.modal, {
      yPercent: -50,
      ease: "expo.out",
      duration: 0.8,
      // invalidateOnRefresh: true,
      autoAlpha: 1,
      onStart: () => {
        this.DOM.pageBackground.classList.add("page-cover-opacity")
        this.html.classList.add("js-overflow-hidden")
        this.body.classList.add("js-overflow-hidden")
        window.lenis ? window.lenis.stop() : ""
      },
      onComplete: () => {
        this.DOM.modal.classList.add("visible")
      },
    })
  }

  closeModal() {
    gsap.to(this.DOM.modal, {
      yPercent: 20,
      ease: "expo.in",
      duration: 0.4,
      autoAlpha: 0,
      // invalidateOnRefresh: true,
      onComplete: () => {
        this.DOM.modal.classList.remove("visible")
        this.DOM.pageBackground.classList.remove("page-cover-opacity")
        this.html.classList.remove("js-overflow-hidden")
        this.body.classList.remove("js-overflow-hidden")
        window.lenis ? window.lenis.start() : ""
      },
    })
  }

  toggleModal() {
    // remove Header
    if (this.DOM.fullScreen.classList.contains("opacity-full")) {
      this.DOM.fullScreen.classList.remove("opacity-full")
      this.header.closeDropdowns()
      this.DOM.toggleIcon.classList.remove("toggle_on")
      this.body.style.overflow = "visible"
    }

    this.DOM.modal.classList.contains("visible")
      ? this.closeModal()
      : this.openModal()
  }

  resizeEvent() {
    this.DOM.modal.classList.remove("visible")
    this.DOM.pageBackground.classList.remove("page-cover-opacity")
    this.html.classList.remove("js-overflow-hidden")
    this.body.classList.remove("js-overflow-hidden")
    // kill the animation
    gsap.killTweensOf(this.DOM.modal)
    gsap.set(this.DOM.modal, { yPercent: 20, xPercent: -50, autoAlpha: 0 })
  }

  eventListeners() {
    this.DOM.modalButton.addEventListener("click", this.toggleModal.bind(this))
    this.DOM.modalClose.addEventListener("click", this.toggleModal.bind(this))

    // close modal by clicking outside
    this.DOM.pageBackground.addEventListener("click", (e) => {
      if (e.target === this.DOM.pageBackground) this.toggleModal()
    })

    window.addEventListener("resize", this.resizeEvent.bind(this))

    // close modal on esc
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") this.toggleModal()
    })
  }
}
