import { gsap, ScrollTrigger } from "gsap/all"
gsap.registerPlugin(ScrollTrigger)

// we are using the WP REST API to grab the posts for our WP FILTER
export default class PostDisplay {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.holder = this.DOM.el.querySelector(".blog__holder")
    this.DOM.buttons = [...this.DOM.el.querySelectorAll(".cat-list_item")]
    this.DOM.tags = [...document.querySelectorAll(".js-tag-item")]
    this.DOM.category = this.DOM.el.querySelector(".js-cat-title")
    this.DOM.select = this.DOM.el.classList.contains("show-post-display")
    this.DOM.sidebar = this.DOM.el.querySelector(".js-post-display-sidebar")

    this.tags = []
    this.allPosts = []
    this.skeletonPostsArray = []

    this.init()
  }

  async fetchPosts() {
    // grabs all posts
    const response = await fetch("/wp-json/wp/v2/posts?per_page=99")

    const data = await response.json()

    // console.log(response)

    // if (!response) {
    //   console.log("no data")
    // }

    // console.log(data)
    // get acf data for each post
    data.forEach((item) => {
      const tags = []
      // console.log(tags)
      item.tag_names.forEach((tag) => {
        const tagname = tag.toLowerCase()
        tags.push(tagname)
      })

      // sort out date
      let date = new Date(item.date).toDateString()

      const obj = {
        id: item.id,
        image: item.featured_image_url,
        title: item.title.rendered,
        date: date,
        quote: item.acf.quote,
        permalink: item.link,
        tags: tags,
      }

      this.allPosts.push(obj)

      // this.allPosts.forEach((obj) => {
      //   this.showPosts(obj)
      // })
    })

    if (data) {
      this.DOM.sidebar.classList.add("js-clickable")
    }
  }

  filter(event) {
    // console.log("filter")
    event.preventDefault()
    this.activeTab(event)

    this.DOM.holder.innerHTML = ""

    this.filteredPosts = []

    // add active for selected filter
    event.target.classList.add("active")
    // grab data attribute
    this.currentCategory = event.currentTarget.dataset.slug.toLowerCase()

    //    console.log(this.currentCategory)

    // match currentcategory with posts and create array of posts
    this.allPosts.forEach((post) => {
      // console.log(post)
      if (post.tags.indexOf(this.currentCategory) > -1) {
        //In the array!
        this.filteredPosts.push(post)
      }
    })

    // empty out all remaining posts
    this.hidePosts()

    if (!this.filteredPosts.length) {
      // empty or does not exisit
      // console.log('no posts')
      this.allPosts.forEach((post) => {
        this.showPosts(post)
      })
    } else {
      this.filteredPosts.forEach((post) => {
        this.showPosts(post)
      })
    }
  }

  showSkeletonPosts(id) {
    const postHTML = document.createElement("div")
    postHTML.classList.add("blog-item-post")
    postHTML.classList.add("blog-item-post--grey")
    postHTML.innerHTML = this.skeletonPosts(id)
    this.DOM.holder.appendChild(postHTML)

    return postHTML
  }

  hidePosts() {
    this.DOM.holder.innerHTML = ""
  }

  skeletonPosts(id) {
    return `
            <div class="blog-item" data-id="${id}">
                <article class="blog-item__inner">
                    <div class="blog__image"></div>
                    <div class="blog-text">
                       <div class="blog-text-top">
                       <div class="blog-item-tags"></div>
                        <h4 class='approach'></h4>
                        <p class='blog-readtime'></p>
                        </div>
                        <button class="just-arrow"></button>
                    </div>
                </article>
            </div>    
        `
  }

  // in loop
  showPosts(post) {
    const postItem = post
    const thisSkeletonPost = this.showSkeletonPosts(postItem.id)

    setTimeout(() => {
      const postHTML = document.createElement("a")
      postHTML.classList.add("blog-item-post")
      postHTML.href = post.permalink
      postHTML.innerHTML = this.newPost(postItem)
      this.DOM.holder.removeChild(thisSkeletonPost)
      this.DOM.holder.appendChild(postHTML)
    }, 2000)
  }

  onResize() {}
  newPost(post) {
    const tags = post.tags.map((tag) => {
      return `<span class="Tag">${tag}</span>`
    })
    return `
            <div class="blog-item">
                <article class="blog-item__inner">
                    <div class="blog__image">
                      ${post.image ? '<img src="' + post.image + '">' : ""}
                    </div>
                    <div class="blog-text">
                       <div class="blog-text-top">
                       <div class="blog-item-tags visually-hidden">
                          ${tags.join(" ")}
                       </div>

                       <p class='blog-readtime p-small'>
                       ${post.date} ${
      post.readTime ? "| " + post.readTime + "min read" : ""
    } 
                       </p>

                        <h5 class='approach'>${post.title}</h5>
                  


                        ${
                          post.quote
                            ? '<div class="blog__item-para"><p>' +
                              post.quote +
                              "</p></div>"
                            : ""
                        } 
                        
                        </div>
                        <button class="PostSlider__item-button btn--sub-main">Read more</button>
                    </div>
                </article>
            </div>    
        `
  }

  activeTab(event) {
    //Scroll to blog section

    // scroll to page section
    this.DOM.el.scrollIntoView({ block: "start", behavior: "smooth" })

    this.DOM.tags.forEach((tag) => {
      tag.classList.remove("js-active")
    })

    this.DOM.category.classList.remove("js-active")

    event.currentTarget.classList.add("js-active")
  }

  showAllPosts(event) {
    this.activeTab(event)

    this.hidePosts()

    this.allPosts.forEach((post) => {
      this.showPosts(post)
    })
  }

  addEventListeners() {
    this.DOM.tags.forEach((tag) => {
      tag.addEventListener("click", this.filter.bind(this))
    })

    this.DOM.category.addEventListener("click", this.showAllPosts.bind(this))
  }

  init() {
    this.onResize()
    this.fetchPosts()
    this.addEventListeners()
  }
}
