// Hero
import HeroSlideshow from "../Blocks/hero/HeroSlideshow"
import HeroFullWidth from "../Blocks/Hero/HeroFullWidth"
import HeroFullWidthLottie from "../Blocks/hero/HeroFullWidthLottie"
import HeroContact from "../Blocks/hero/HeroContact"
import HeroSearch from "../Blocks/hero/HeroSearch"
import HeroVideo from "../Blocks/Hero/HeroVideo"
import HeroHome from "../Blocks/Hero/HeroHome"
import LandingPageHero from "../Blocks/Hero/LandingPageHero"

export default class HeroManager {
  constructor({ hero, header, screenSize, preloader, popup, scroll }) {
    this.hero = hero
    this.header = header
    this.scroll = scroll
    this.screenSize = screenSize
    this.preloader = preloader
    this.popup = popup

    if (this.hero) {
      this.currentHeroValue = this.hero.getAttribute("data-hero")
      this.init()
    } else {
      //If hero not available doing something, this.header.headerBackground()
    }
  }

  init() {
    this.heroCheck(this.currentHeroValue)
  }

  heroCheck(hero) {
    // split classnames into an array
    let heroClassnames = hero.split(" ")
    heroClassnames = heroClassnames.filter(
      (cl) => cl !== "alignfull" || !cl.includes("wp-block")
    )
    // flatten array
    this.currentHeroValue = heroClassnames[0]

    switch (this.currentHeroValue) {
      case "HeroFullWidth":
        this.theHero = new HeroFullWidth({
          header: this.header,
          scroll: this.scroll,
        })
        break
      case "HeroContact":
        this.theHero = new HeroContact()
        break
      case "LandingPageHero":
        this.theHero = new LandingPageHero()
        break
      case "HeroFullWidthLottie":
        this.theHero = new HeroFullWidthLottie()
        break
      case "HeroHome":
        this.theHero = new HeroHome({
          header: this.header,
          scroll: this.scroll,
        })
        break
      case "HeroSlideshow":
        this.theHero = new HeroSlideshow()
        break
      case "HeroSearch":
        this.theHero = new HeroSearch()
        break
      case "HeroVideo":
        this.theHero = new HeroVideo()
        break
    }

    // if gutenberg
    if (typeof wp != "undefined" && wp.blockEditor) {
      if (this.theHero.gutenberg) {
        this.theHero.gutenberg()
      }
    } else {
      if (this.theHero) this.theHero.create()
    }
  }

  deleteHero() {
    if (this.theHero) {
      delete this.theHero
    }
  }

  // barba enter
  enterPageTransition() {
    this.currentHero = document.querySelector("[data-hero]")
    if (this.currentHero) {
      this.currentHeroValue = this.currentHero.getAttribute("data-hero")
    }

    this.heroCheck(this.currentHeroValue)
  }

  // does block need to enable gutenberg  back end editing
  gutenberg() {
    if (this.hero.gutenberg) {
      this.hero.gutenberg()
    }
  }

  onResize(screenSize) {
    this.screenSize = screenSize

    // console.log(this)
    //  console.log('heros resize')
  }
}
