export default class SimplePageBanner {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.button = this.DOM.el.querySelector(".js-button")
    console.log("hello")
    this.addEventListeners()
  }

  scrollToSection(e) {
    // check if button href is a hash
    if (this.DOM.button.href.includes("#")) {
      e.preventDefault()
      // get the hash
      const hash = this.DOM.button.href.split("#")[1]
      // get the target element
      const target = document.getElementById(hash)

      // scroll to the target
      if (window.lenis) {
        // smooth scroll
        window.lenis.scrollTo(target, {
          duration: 1.2,
          offset: -window.innerHeight / 5,
          // ease: 'power3.inOut'
        })
      } else {
        section.scrollIntoView({ behavior: "smooth" })
      }
    }
  }

  addEventListeners() {
    this.DOM.button.addEventListener("click", this.scrollToSection.bind(this))
  }
}
