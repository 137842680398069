import Header from "./Components/Header"
import Preloader from "./Components/Preloader"
import Modal from "./Components/Modal"
import Popup from "./Components/Popup"
import Cursor from "./Components/Cursor"
import CookiePolicy from "./Components/CookiePolicy"
import Scroll from "./Components/Scroll"
// import Scroll from './Components/Scroll'

//Classes
import BlockManager from "./Classes/BlockManager"
import HeroManager from "./Classes/HeroManager"
import Detection from "./Classes/Detection"

// Page Transitions
import Fade from "./Transitions/Fade"

// Utils
// import { getMousePos } from 'Utils'

//templates
import textChild from "./Templates/textChild"

class App {
  constructor() {
    // Checking if we are viewing on backend or not
    // turn on for gutenberg
    if (typeof wp != "undefined") {
      // BACKEND

      if (wp.blockEditor) {
        // GUTENBERG
        console.log("Backend: Gutenberg")
        window._wpLoadBlockEditor.then(this.runGutenberg.bind(this))
        return
      } else {
        // console.log(wp, Object.keys(wp), window.acf)
        // Frontend but landed here by accident
        // Backend but not Gutenberg
        window.acf ? console.log("Backend: Not Gutenberg") : this.initFrontend()
      }
    } else {
      // console.log("running now")
      // FRONTEND
      this.initFrontend()
    }
  }

  initFrontend() {
    this.consoleMessage()
    //  this.detection()
    this.createContent()
    this.createPopup()
    this.createHero()
    this.createSettings()
    this.createBlocks()
    this.addEventListeners()
    this.pageTransitions()
    this.onResize()
  }

  pageTransitions() {
    this.headerFader = document.querySelector(".js-header-fader")
    this.transitions = new Fade(this.headerFader)
  }

  consoleMessage() {
    //  console.clear()
    console.groupCollapsed(
      "%cThis website was developed by Dirty Martini Marketing",
      "color:white, background:black"
    )
    console.log(
      "For all your website needs, please contact us | https://dirty-martini.com"
    )
    console.log(
      "Website theme built by Maxwell Kirwin https://maxwellkirwin.co.uk"
    )

    console.groupEnd()
  }

  createPopup() {
    this.popupInView = document.querySelector(".js-contact-pop-up")

    if (this.popupInView) {
      this.popup = new Popup({
        header: this.header,
      })
    }
  }

  createSettings() {
    this.preloaderInView = document.querySelector(".pre_loader")
    this.modalInView = document.querySelector("#myModal")
    this.cursorOnPage = document.querySelector(".cursor")
    this.devTools = document.querySelector(".dev-tools")
    this.gdpr = document.querySelector("#gdpr-box")

    if (this.preloaderInView) {
      this.preloader = new Preloader()
      this.preloader.once("completed", this.onPreloaded.bind(this))
    } else {
      if (this.HeroManager.theHero) {
        this.HeroManager.theHero.onLoad()
      }
      if (this.gdpr) {
        this.cookiePolicy = new CookiePolicy()
      }
    }

    if (this.modalInView) {
      this.modal = new Modal()
    }
    if (this.cursorOnPage) {
      // Initialize custom cursor
      this.mouse = { x: 0, y: 0 }
      this.cursor = new Cursor({ mouse: this.mouse })
    }
  }

  createContent() {
    this.scrollWrapper = document.querySelector("#smooth-wrapper")
    this.scrollContent = document.querySelector("#smooth-content")

    this.scroll = new Scroll({
      scrollWrapper: this.scrollWrapper,
      scrollContent: this.scrollContent,
    })

    this.scrolling = document.querySelector(".header__activate_scrolling")
    this.header = new Header({
      scroll: this.scroll,
      container: this.scrollWrapper,
    })
    this.body = document.querySelector("body")
  }

  onPreloaded() {
    //  console.log('run on preloadered')
    this.preloader.destroy()
    // run hero animations

    if (this.gdpr) {
      this.cookiePolicy = new CookiePolicy()
    }

    // check if there is a hero on the page
    if (this.HeroManager.theHero) {
      this.HeroManager.theHero.onLoad()
    }
  }

  checkforGutenberg() {
    const hero = document.querySelector("[data-hero]")
    //  const block = document.querySelector('[data-block]')
    if (hero) {
      /*post-title-0 is ID of Post Title Textarea*/
      //Actual functions goes here
      this.blocksLoaded = true
      this.createHero()
      this.createBlocks()
    }
    if (this.blocksLoaded) {
      clearInterval(this.loadingTimer)
    }
  }

  runGutenberg() {
    this.consoleMessage()
    console.log("Backend")
    // check for heros  & run them
    this.blocksLoaded = false
    this.loadingTimer = setInterval(this.checkforGutenberg.bind(this), 500)
  }

  createHero() {
    this.currentHero = document.querySelector("[data-hero]")
    this.preloaderInView = document.querySelector(".pre_loader")
    this.HeroManager = new HeroManager({
      hero: this.currentHero,
      scroll: this.scroll,
      header: this.header,
      screenSize: this.screenSize,
      preloader: this.preloaderInView,
      popup: this.popup,
    })
  }

  createBlocks() {
    this.blocks = [...document.querySelectorAll("[data-block]")]
    this.blocksOnPage = []

    // Check this if you want to create a new JS Block
    this.BlockManager = new BlockManager({
      blocks: this.blocks,
      scroll: this.scroll,
      wrapper: this.scrollWrapper,
      screenSize: this.screenSize,
    })

    // if no blocks on the page, run createPages
    // if (!this.blocksOnPage.length) {
    if (!this.blocks.length) {
      this.createPages()
    }
  }
  createPages() {
    this.pages = {}

    // add a if statement for each page
    if (this.body.classList.contains("page-text-child-template-php")) {
      this.pages.textChild = new textChild()
    } else {
      //  'no pages here'
    }
  }

  detection() {
    this.screenSize = ""
    this.detection = new Detection(this.screenSize)
    this.screenSize = this.detection.screenSize
  }

  onResize() {
    // re calculate screen size
    //  this.detection.init()
    //    this.screenSize = this.detection.screenSize
    //
    // if (this.mobile) {
    //     console.log('mobile')
    //     this.ImageSection = new ImageSection.widthChange()
    // }

    // when we have smooth scroll enabled
    //_.isEmpty(this.smoothScroll)

    // run on Resize for all blocks
    if (this.BlockManager) {
      this.BlockManager.onResize(this.screenSize)
    }
  }

  addEventListeners() {
    // check for gutenberg
    if (typeof wp != "undefined") {
      if (wp.blockEditor) {
        // GUTENBERG
        window._wpLoadBlockEditor.then(this.runGutenberg.bind(this))
        return
      }
    }

    // check for frontend
    if (this.cursorOnPage) {
      // Mouse effects on all links and others
      ;[...document.querySelectorAll("a")].forEach((link) => {
        link.addEventListener("mouseenter", () => this.cursor.enter())
        link.addEventListener("mouseleave", () => this.cursor.leave())
      })
    }

    window.addEventListener("resize", this.onResize.bind(this))

    window.addEventListener("scroll", this.header.scrolling.bind(this))

    if (this.devTools) {
      const gridSelector = this.devTools.querySelector("#griddevtools")
      const darkmode = this.devTools.querySelector("#darkmodedevtools")

      const grid = document.querySelector(".dt-grid")
      gridSelector.addEventListener("change", (event) => {
        if (event.currentTarget.checked) {
          grid.classList.add("on")
        } else {
          grid.classList.remove("on")
        }
      })
      darkmode.addEventListener("change", (event) => {
        this.body.classList.toggle("dark-mode")
      })
    }
  }
}

const website = new App()
