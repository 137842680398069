export default class ImageSection {
    constructor({ screenSize, id }) {
        this.screenSize = screenSize
        this.DOM = { el: document.getElementById(id) }
        this.init()
    }

    // run on resize
    onResize(screensized) {

    }

    init() {

        // run on load
        this.onResize(this.screenSize)
    }
}



