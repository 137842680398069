import { gsap, ScrollTrigger } from "gsap/all"
gsap.registerPlugin(ScrollTrigger)

export default class TabServices {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.iconBoxes = [...this.DOM.el.querySelectorAll(".icon_box")]
    this.DOM.tabs = [...document.querySelectorAll(".icon_box_description")]
    this.DOM.masterTab = this.DOM.el.querySelector(".master-tab-dropdown")

    // on page load open first tab
    this.DOM.iconBoxes[0].classList.add("js-open")
    this.DOM.tabs[0].classList.add("tab-open")
    this.DOM.masterTab.classList.add("tab-open")
    // this.DOM.masterTab.style.height = `${this.DOM.tabs[0].offsetHeight}px`

    this.currentTab = this.DOM.tabs[0]

    this.init()
  }

  init() {
    this.addEventListeners()
  }
  showTabs(event) {
    // OBJECTS
    const icon = event.currentTarget
    const currentTabSelector = icon.dataset.menu

    // console.log("yoo")

    this.DOM.tabs.forEach((tab) => {
      if (tab.dataset.menu === currentTabSelector) {
        this.currentTab = tab
        return this.currentTab
      }
    })

    // MASTER  TAB
    // Show if no tabs are active

    if (this.DOM.masterTab.classList.contains("tab-open")) {
      // master tab is already open
    } else {
      // open master tab
      this.DOM.masterTab.style.height = `${this.currentTab.offsetHeight}px`
      this.DOM.masterTab.classList.add("tab-open")
    }

    // if the current target already contains tab-open remove master tab
    if (icon.classList.contains("js-open")) {
      this.DOM.masterTab.classList.remove("tab-open")
      this.DOM.masterTab.style.height = `${0}px`
      // remove old content
      this.DOM.tabs.forEach((tab) => {
        tab.classList.remove("tab-open")
      })

      icon.classList.remove("js-open")
    } else {
      // remove old content
      this.DOM.tabs.forEach((tab) => {
        tab.classList.remove("tab-open")
      })

      // remove all viewed tabs
      this.DOM.iconBoxes.forEach((icon) => {
        icon.classList.remove("js-open")
      })

      // load in new content
      icon.classList.add("js-open")
      this.currentTab.classList.add("tab-open")
      this.DOM.masterTab.style.height = `${this.currentTab.offsetHeight}px`
    }
  }

  // Necessary to have, leave empty if not needed
  gutenberg() {
    //this.init()
  }

  onTabResize() {
    if (this.currentTab && this.DOM.masterTab.classList.contains("tab-open")) {
      this.DOM.masterTab.style.height = `${this.currentTab.offsetHeight}px`
    }

    // if tab is open on resize, resize tab
  }

  addEventListeners() {
    this.DOM.iconBoxes.forEach((box) => {
      box.addEventListener("click", this.showTabs.bind(this))
    })

    window.addEventListener("resize", this.onTabResize.bind(this))
  }
}
