import { SplitTitle } from "../../Animations/SplitTitle"
import { gsap } from "gsap/all"

export default class HeroContact {
  constructor() {
    this.DOM = { el: document.querySelector(".HeroContact") }
    this.DOM.title = this.DOM.el.querySelector(".hc-title")
    this.DOM.desc = this.DOM.el.querySelector(".hc-desc")
    this.DOM.form = this.DOM.el.querySelector(".hero__form")
  }

  create() {
    this.title = SplitTitle(this.DOM.title)
    gsap.set([this.DOM.desc], { autoAlpha: 0 })

    // split text
    this.onResize()
  }

  animate() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: "expo.out",
        duration: 0.8,
        invalidateOnRefresh: true
      },
    })

    this.tlHero
      .to([this.title], {
        yPercent: 0,
        duration: 0.8,
        stagger: "0.2",
      })
      .to([this.DOM.desc], { autoAlpha: 1 })
  }

  onResize() {
    this.animate()
  }

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  onLoad() {}
}
