export default class TeamGrid {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.form = this.DOM.el.querySelector(".js-search-form")
    this.DOM.input = this.DOM.form.querySelector(".js-search-field")
    this.DOM.holder = this.DOM.el.querySelector(".js-holder")
    this.DOM.masterHolder = this.DOM.el.querySelector(".js-holder-master")
    this.DOM.loader = this.DOM.el.querySelector(".js-loader")
    this.DOM.disclaimer = this.DOM.el.querySelector(".js-dislaimer")

    // Events
    this.updateValue = new CustomEvent("updateSearchValue", {
      bubbles: true,
      detail: { searchTerm: () => this.DOM.input.value },
    })

    this.searchInputTerm = this.searchTerm.bind(this)

    this.teamMembers = []

    this.init()
  }

  // run on resize
  onResize() {}

  async searchTerm(e) {
    e.preventDefault()
    // grab current search term
    const term = this.updateValue.detail.searchTerm()

    if (this.teamMembers.length > 0) {
      this.renderSearchResults(this.teamMembers, term)
    } else {
      this.renderNoResults(term)
    }
  }

  renderSearchResults(results, term) {
    const regex = new RegExp(term, "i")
    let tick = false
    this.filteredTeam = []

    // firstly wipe holder and show loader
    this.DOM.holder.innerHTML = ""
    this.DOM.disclaimer.classList.remove("js-active")
    this.DOM.loader.classList.add("js-active")

    // then find results
    results.forEach((result) => {
      if (regex.test(result.title)) {
        //  console.log(term, result)
        this.filteredTeam.push(result)
        tick = true
      }
    })

    // if no responses disclaimer section
    if (!tick) {
      // console.log("sorry no team")
      this.DOM.disclaimer.classList.add("js-active")
      this.DOM.loader.classList.remove("js-active")
    }

    if (this.filteredTeam.length) {
      // render results
      this.showFilteredTeam()
    }
  }

  showFilteredTeam() {
    // show filtered
    // console.log("show filtered team")

    // Create a holder to append to the DOM
    const holder = document.createElement("div")
    holder.classList.add("TeamGrid__holder")

    // append all new divs to holder
    this.filteredTeam.forEach((item) => {
      const teamMemberInfo = this.TeamMemberDiv(item)
      const teamMemberDiv = document.createElement("a")
      teamMemberDiv.classList.add("TeamMemberCard")
      teamMemberDiv.href = item.link
      teamMemberDiv.innerHTML = teamMemberInfo
      holder.appendChild(teamMemberDiv)
    })

    // append holder to DOM
    setTimeout(() => {
      this.DOM.loader.classList.remove("js-active")
      this.DOM.holder.appendChild(holder)
    }, 1000)
  }

  init() {
    this.fetchTeam()
    this.addEventListeners()
  }

  async fetchTeam() {
    // grabs all team
    const response = await fetch(
      "/wp-json/wp/v2/team?acf_format=standard&per_page=99"
    )
    const data = await response.json()

    // get acf data for each post
    data.forEach((item) => {
      const tags = []
      //   item.tag_names.forEach((tag) => {
      //     const tagname = tag.toLowerCase()
      //     tags.push(tagname)
      //   })

      const obj = {
        //     id: item.id,
        //     image: item.featured_image_url,
        title: item.acf.name,
        additionalTitle: item.acf.additional_title,
        location: item.acf.location,
        jobTitle: item.acf.job_title,
        link: item.link,
        image: item.acf.team_member_photo.url,
        //     author: item.acf.author,
        //     date: item.acf.article_date,
        //     readTime: item.acf.article_read_time,
        //     quote: item.acf.quote,
        //     permalink: item.link,
        //     tags: tags,
      }

      this.teamMembers.push(obj)
    })
  }

  TeamMemberDiv(id) {
    return `
    <div class="TeamMemberCard__image">
        <img src="${id.image}"/>
    </div>
    <div class="TeamMemberCard__text-section">
        <div class="TeamMemberCard__text-section-inner">

            <div class="TeamMemberCard__text-group">
                <h5 class="TeamMemberCard__name">${id.title}</h5>
                <p class="TeamMemberCard__job p-small">${id.jobTitle}</p>
                ${
                  id.addJob
                    ? '<p class="TeamMemberCard__job p-small">' +
                      id.addJob +
                      "</p>"
                    : ""
                }
            </div>
            <div class="TeamMemberCard__button">
                <button class="just-arrow" aria-label="Arrow Button"></button>
            </div>
        </div>
    </div>
    `
  }

  addEventListeners() {
    // The form element listens for the custom "updateSearchValue" event and then consoles the output of the passed text() method
    // this.DOM.form.addEventListener("updateSearchValue")

    // listen for text changes on input field, when 'input' dispatch custom event
    this.DOM.input.addEventListener("input", (e) => {
      // clear results
      //   this.DOM.resultsDiv.innerHTML = ""
      // dispatch event
      e.target.dispatchEvent(this.updateValue)
    })

    // this.DOM.form.addEventListener("keyup", (e) => {
    //   if (e.keyCode === 27) {
    //     console.log("gg")
    //     this.searchInputTerm()
    //   }
    // })

    this.DOM.form.addEventListener("submit", this.searchInputTerm)
  }
}
