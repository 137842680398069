import Timeline from "../Blocks/Page/Timeline"
// import Services from '../Blocks/Page/Services'
import Testimonials from "../Blocks/Page/Testimonials"
import Gallery from "../Blocks/Page/Gallery"
import Accordion from "../Blocks/Page/Accordion"
import List from "../Blocks/Page/List"
import FAQ from "../Blocks/Page/Faq"
import Slideshow from "../Blocks/Page/Slideshow"
import ImageSection from "../Blocks/Page/ImageSection"
import FullWidthImage from "../Blocks/Page/FullWidthImage"
import ContactSection from "../Blocks/Page/ContactSection"
import TabServices from "../Blocks/Page/TabServices"
import ServicesSearch from "../Blocks/Page/ServicesSearch"
import LandingPageLinks from "../Blocks/Page/LandingPageLinks"
import TeamForm from "../Blocks/Page/TeamForm"
import SimplePageBanner from "../Blocks/Page/SimplePageBanner"

// post
import PostDisplay from "../Blocks/Post/PostDisplay"
import PostSlider from "../Blocks/Post/PostSlider"
import TeamGrid from "../Blocks/Post/TeamGrid"

// import ImageSection from 'Blocks/Page/ImageSection'

export default class BlockManager {
  constructor({ blocks, scroll, wrapper, screenSize }) {
    this.blocks = blocks
    this.scroll = scroll
    this.screenSize = screenSize
    this.scrollWrapper = wrapper
    this.currentBlocks = []
    this.onFirstPageLoad()

    if (typeof wp != "undefined" && wp.blockEditor) {
      this.gutenberg()
    }
  }

  onFirstPageLoad() {
    //  console.log(this.blocks)
    this.blocks.forEach((block) => {
      this.blockCheck(block)
    })
  }

  blockCheck(block) {
    if (!this.currentBlocks) {
      this.currentBlocks = []
    }

    const className = block.className
    const id = block.id

    // split classnames into an array
    let keys = className.split(" ")
    // filter out any classes that match 'alignfull' or contain 'wp-block'
    keys = keys.filter((cl) => cl !== "alignfull" || !cl.includes("wp-block"))

    // flatten array
    keys = keys[0]

    switch (keys) {
      case "PostDisplay":
        const pd = new PostDisplay(id)
        this.currentBlocks.push(pd)
        break
      case "SimplePageBanner":
        const spb = new SimplePageBanner(id)
        this.currentBlocks.push(spb)
        break
      case "TeamGrid":
        const teamgrid = new TeamGrid(id)
        this.currentBlocks.push(teamgrid)
        break
      case "PostSlider":
        const psd = new PostSlider(id)
        this.currentBlocks.push(psd)
        break
      case "LandingPageTeamForm":
        const tf = new TeamForm(id)
        this.currentBlocks.push(tf)
        break
      case "LandingPageLinks":
        const lps = new LandingPageLinks(id)
        this.currentBlocks.push(lps)
        break
      case "Testimonials":
        const t = new Testimonials({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id,
        })
        this.currentBlocks.push(t)
        break
      case "ContactSection":
        const cs = new ContactSection(id)
        this.currentBlocks.push(cs)
        break
      case "ServicesSearch":
        const ss = new ServicesSearch(id)
        console.log(ss)
        this.currentBlocks.push(ss)
        break
      case "TabServices":
        const tc = new TabServices(id)
        this.currentBlocks.push(tc)
        break
      case "Slideshow":
        const s = new Slideshow({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id,
        })
        this.currentBlocks.push(s)
        break
      case "Accordion":
        const a = new Accordion({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id,
        })
        this.currentBlocks.push(a)
        break
      case "List":
        const l = new List(id)
        this.currentBlocks.push(l)
        break
      case "FullWidthImage":
        const fwi = new FullWidthImage({
          scroll: this.scroll,
          screenSize: this.screenSize,
          container: this.scrollWrapper,
          id: id,
        })
        this.currentBlocks.push(fwi)
        break
      case "Gallery":
        const g = new Gallery(id)
        this.currentBlocks.push(g)
        break
      case "FAQ":
        const faq = new FAQ({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id,
        })
        this.currentBlocks.push(faq)
        break
      case "Timeline":
        const tl = new Timeline({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id,
        })
        this.currentBlocks.push(tl)
        break
    }
  }

  deleteBlocks() {
    if (this.currentBlocks) {
      delete this.currentBlocks
    }
  }

  enterPageTransition(next) {
    // init
    const blocksOnNewPage = [...document.querySelectorAll("[data-block]")]

    blocksOnNewPage.forEach((block) => {
      this.blockCheck(block)
    })
  }

  // does block need to enable gutenberg  back end editing
  gutenberg() {
    this.currentBlocks.forEach((block) => {
      if (block.gutenberg) block.gutenberg()
    })
  }

  onResize(screenSize) {
    this.screenSize = screenSize

    this.currentBlocks.forEach((block) => {
      if (block.onResize) {
        block.onResize(this.screenSize)
      }
      // this.blocks[blocks].onResize(this.screenSize)
    })

    //  console.log('blocks resize')
  }
}
