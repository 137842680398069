import Lenis from "@studio-freight/lenis"

export default class Scroll {
  constructor({ scrollWrapper, scrollContent }) {
    this.scrollWrapper = scrollWrapper
    this.scrollContent = scrollContent

    // select all links with hashes
    this.hashLinks = document.querySelectorAll('a[href*="#"]')

    this.scroll = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
      direction: "vertical", // vertical, horizontal
      gestureDirection: "vertical", // vertical, horizontal, both
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    })

    window.lenis = this.scroll

    const raf = (time) => {
      this.scroll.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)

    this.addEventListeners()
  }

  refresh() {
    // console.log(ScrollSmoother.get().scrollTrigger)
    //  ScrollSmoother.get().scrollTrigger.refresh()
  }

  // isScrolling() {
  //   ScrollTrigger.update()
  //   //  console.log(this)
  // }

  scrollTo(target, options) {
    // {offset, duration, easing, immediate}
    return this.scroll.scrollTo(target, options)
  }

  addEventListeners() {
    //get scroll value
    // this.scroll.on(
    //   'scroll',
    //   ({ scroll, limit, velocity, direction, progress }) => {
    //     console.log({ scroll, limit, velocity, direction, progress })
    //   }
    // )
  }
}
