import imagesLoaded from 'imagesloaded'
import EventEmitter from '../Classes/EventEmitter'
import gsap from "gsap";

import { GSDevTools, DrawSVGPlugin } from 'gsap/all'
gsap.registerPlugin(GSDevTools, DrawSVGPlugin)

export default class Preloader extends EventEmitter {
  constructor() {
    super()
    this.DOM = { el: document.querySelector('.pre_loader') }
    this.DOM.hidePage = document.querySelector('.hide-whole-page')
    this.DOM.curtain = this.DOM.el.querySelector('.curtain')
    this.DOM.images = [...document.querySelectorAll('img')]
    this.DOM.texts = [...document.querySelectorAll('.preloader__text')]
    // this.DOM.textGroup = this.DOM.el.querySelector('.preloader__text-group > h3')
    this.DOM.progressCircle = this.DOM.el.querySelector('circle')
    this.length = 0

    this.DOM.firstText = this.DOM.texts.shift();  // Removes the first element from an array and returns only that element.
    this.DOM.lastText = this.DOM.texts.pop();    // Removes the last element from an array and returns only that element.
    gsap.set(this.DOM.texts, {autoAlpha: 0, yPercent:-110 }) // sets to hidden
    gsap.set(this.DOM.lastText, {autoAlpha: 0, yPercent:-110 }) // sets to hidden

    gsap.set([ this.DOM.firstText], {autoAlpha: 1, yPercent:0 })

    this.init()

    // console.log('Preloader', this.DOM)
  }

  imageLoader() {
    const imgLoad = imagesLoaded(this.DOM.images)
    imgLoad.on('progress', (instance, img) => {
      this.onImagesLoaded(instance, img)
    })
  }

  init() {
    this.loaderTimeline = gsap.timeline({
      defaults: {
        ease: 'expo.out'
      },
      onStart: () => {
        this.DOM.curtain.classList.add('js-display')
      },
      onComplete: () => {
        this.imageLoader()
      }
    })

    // Checks if there are three and runs apropriately
    if (this.DOM.texts != '') {
      this.loaderTimeline
        .to(this.DOM.firstText, { delay: 1, autoAlpha: 0, yPercent: 50, duration: 1, ease: "back.inOut(1.7)" })
        .to(this.DOM.texts, { autoAlpha: 1, yPercent: 0, stagger: 1, duration: 1, ease: "back.inOut(1.7)" })
        .to(this.DOM.texts, { autoAlpha: 0, yPercent: 50, duration: 1, ease: "back.inOut(1.7)" })
        .to(this.DOM.lastText, { autoAlpha: 1, yPercent: 0, duration: 1, ease: "back.inOut(1.7)" })
    } else {
      this.loaderTimeline
        .to(this.DOM.firstText, { delay: 1, autoAlpha: 0, yPercent: 50, duration: 1, ease: "back.inOut(1.7)" })
        .to(this.DOM.lastText, { autoAlpha: 1, yPercent: 0, duration: 1, ease: "back.inOut(1.7)" })  
    }
  }

  imageLoader() {
    const imgLoad = imagesLoaded(this.DOM.images)
    imgLoad.on('progress', (instance, img) => {
      this.onImagesLoaded(instance, img)
    })
  }

  onImagesLoaded(instance, img) {
    setTimeout(() => {
      this.DOM.hidePage.classList.add('hide')
      this.emit('completed', 'yes')
    }, 2000)
  }

  destroy() {
    this.DOM.el.classList.add('hidden__preloader')
      setTimeout(() => {
      // this.DOM.el.parentNode.removeChild(this.DOM.el)
      }, 1000)
  }

  loop() {
    this.DOM.el.classList.remove('hidden__preloader')
    this.loaderTimeline.restart()
  }

  hide() {
    this.loaderTimeline.pause()
    this.DOM.el.classList.add('hidden__preloader')
  }
}







// import imagesLoaded from 'imagesloaded'
// import EventEmitter from '../Classes/EventEmitter'
// import gsap from "gsap";

// import { GSDevTools, DrawSVGPlugin } from 'gsap/all'
// gsap.registerPlugin(GSDevTools, DrawSVGPlugin)

// export default class Preloader extends EventEmitter {
//   constructor() {
//     super()
//     this.DOM = { el: document.querySelector('.pre_loader') }
//     this.DOM.hidePage = document.querySelector('.hide-whole-page')
//     this.DOM.curtain = this.DOM.el.querySelector('.curtain')
//     this.DOM.images = [...document.querySelectorAll('img')]
//     this.DOM.texts = [...document.querySelectorAll('.preloader__text')]
//     this.DOM.progressCircle = this.DOM.el.querySelector('circle')
//     this.length = 0

//     this.DOM.firstText = this.DOM.texts.shift();  // Removes the first element from an array and returns only that element.
//     this.DOM.lastText = this.DOM.texts.pop();    // Removes the last element from an array and returns only that element. 
//     gsap.set([this.DOM.texts, this.DOM.lastText], {autoAlpha: 0, yPercent:-110 })

//     gsap.set([ this.DOM.firstText], {autoAlpha: 1, yPercent:0 })

//     this.init()
//   }

//   imageLoader() {
//     const imgLoad = imagesLoaded(this.DOM.images)
//     imgLoad.on('progress', (instance, img) => {
//       this.onImagesLoaded(instance, img)
//     })
//   }

//   init() {
//     this.loaderTimeline = gsap.timeline({
//       defaults: {
//         ease: 'expo.out'
//       },
//       onStart: () => {
//         this.DOM.curtain.classList.add('js-display')
//       },
//       onComplete: () => {
//         this.imageLoader()
//       }
//     })

//     this.loaderTimeline
//       // .to(this.DOM.firstText, { delay: 0.5, autoAlpha: 0, yPercent: 50, duration:0.4, ease: 'power4.in' }, 0)
//       // .to([this.DOM.texts, this.DOM.lastText,], { autoAlpha: 1, delay: 0.6, yPercent: 0, stagger: 1, duration: 0.6, ease: "back.inOut(1.7)" }, 0)
//       // .to(this.DOM.texts, { delay: 1.1, autoAlpha: 0, yPercent: 50, stagger: 1, duration:0.4, ease: 'power4.in' }, 0)
//   }

//   imageLoader() {
//     const imgLoad = imagesLoaded(this.DOM.images)
//     imgLoad.on('progress', (instance, img) => {
//       this.onImagesLoaded(instance, img)
//     })
//   }

//   onImagesLoaded(instance, img) {
//     setTimeout(() => {
//       this.DOM.hidePage.classList.add('hide')
//       this.emit('completed', 'yes')
//     }, 2000)
//   }

//   // destroy() {
//   //  this.DOM.el.classList.add('hidden__preloader')
//   //   setTimeout(() => {
//   //   // this.DOM.el.parentNode.removeChild(this.DOM.el)
//   //   }, 2000)
//   // }

//   loop() {
//     this.DOM.el.classList.remove('hidden__preloader')
//     this.loaderTimeline.restart()
//   }

//   hide() {
//     this.loaderTimeline.pause()
//     this.DOM.el.classList.add('hidden__preloader')
//   }
// }