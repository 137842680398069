import { gsap, ScrollTrigger } from "gsap/all"
gsap.registerPlugin(ScrollTrigger)
// core version + navigation, pagination modules:
import Swiper, { Scrollbar, Autoplay } from "swiper"
// configure Swiper to use modules
Swiper.use([Scrollbar, Autoplay])

export default class TeamForm {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.wrapper = this.DOM.el.querySelector(".js-swiper-wrapper")
    this.DOM.slider = this.DOM.el.querySelector(".js-swiper")
    this.DOM.swiperItems = [...this.DOM.el.querySelectorAll(".js-swiper-slide")]
    this.DOM.scrollbar = this.DOM.el.querySelector(".swiper-scrollbar")

    this.init()
  }

  swiperInit() {
    this.swiper = new Swiper(this.DOM.slider, {
      slidesPerView: 3,
      spaceBetween: 5,
      grabCursor: true,
      updateOnImagesReady: true,
      loop: true,
      scrollbar: {
        el: this.DOM.scrollbar,
        hide: false,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 1.75,
          spaceBetween: 15,
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        // when window width is >= 640px
        1920: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
    })
  }

  init() {
    if (this.DOM.swiperItems.length > 2) this.swiperInit()
  }
  gutenberg() {
    if (window.acf) {
      this.init()
    }
  }
}
