export default class LandingPageHero {
  constructor() {
    this.DOM = { el: document.querySelector(".LandingPageHero") }
    this.DOM.link = this.DOM.el.querySelector(".js-link")
  }

  create() {
    this.addEventListeners()
  }

  navigateToSections(e) {
    e.preventDefault()

    // get section
    let section = document.querySelector(e.currentTarget.dataset.target)

    // if link isnt realted to apge section return
    if (!section) return (window.location.href = e.currentTarget.href)

    // scroll to section using native browser APIs
    window.lenis
      ? window.lenis.scrollTo(section, {
          duration: 0.66,
          offset: -(window.innerHeight / 4),
        })
      : section.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        })
  }

  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  addEventListeners() {
    if (this.DOM.link)
      this.DOM.link.addEventListener(
        "click",
        this.navigateToSections.bind(this)
      )
  }

  onLoad() {}
}
