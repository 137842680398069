import { SplitTitle } from "../../Animations/SplitTitle"
import { gsap } from "gsap/all"

export default class HeroVideo {
  constructor() {
    this.DOM = { el: document.querySelector(".HeroVideo") }
    this.DOM.title = this.DOM.el.querySelector(".hv-title")
    this.DOM.title = this.DOM.el.querySelector(".hv-subtitle")
  }

  create() {
    this.title = SplitTitle(this.DOM.title)

    // split text
    this.onResize()
  }

  animate() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: "expo.out",
        duration: 0.8,
        invalidateOnRefresh: true
      },
    })

    this.tlHero
      .to([this.title], {
        yPercent: 0,
        duration: 0.8,
        stagger: "0.2",
      })
  }

  onResize() {
    this.animate()
  }

  // Necessary to have, leave empty if not needed
  gutenberg() { }

  onLoad() { }
}