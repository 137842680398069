export default class Gallery{
    constructor (id) {
        this.DOM = {el: document.getElementById(id)}
        this.DOM.galleryInner = this.DOM.el.querySelector('.js-gallery')
        this.DOM.images = [...this.DOM.el.querySelectorAll('img')]
        this.DOM.modal = this.DOM.el.querySelector('.gallery-modal');
        this.DOM.prevButton = this.DOM.el.querySelector('.prev');
        this.DOM.nextButton = this.DOM.el.querySelector('.next')
        this.DOM.modalImg = this.DOM.modal.querySelector('img')
        this.DOM.modalTitle = this.DOM.modal.querySelector('h2')
        this.DOM.modalP = this.DOM.modal.querySelector('figure p')
        this.DOM.currentImage;
   
        this.init()
    }

    showImage (element) {

        const el  = element.currentTarget || element

        if (!el) {
            //  console.info('no image to show');
              return;
         }

         this.DOM.modalImg.src = el.src
         this.DOM.modalTitle.textContent = el.title
         this.DOM.modalP.textContent = el.dataset.description
         this.currentImage = el
         this.openModal()
    }

    openModal() {
        // console.info('Opening Modal...');
        // First check if the modal is already open
        if (this.DOM.modal.matches('.open')) {
        //  console.info('Modal already open');
          return; // stop the function from running
        }
        this.DOM.modal.classList.add('open')
    
        // Event listeners to be bound when we open the modal:
        window.addEventListener('keyup', this.handleKeyUp.bind(this))
        this.DOM.nextButton.addEventListener('click', this.showNextImage.bind(this))
        this.DOM.prevButton.addEventListener('click', this.showPrevImage.bind(this))
      }
    
      closeModal() {
        this.DOM.modal.classList.remove('open');
        // TODO: add event listeners for clicks and keyboard..
        window.removeEventListener('keyup', this.handleKeyUp);
        this.DOM.nextButton.removeEventListener('click', this.showNextImage)
        this.DOM.prevButton.removeEventListener('click', this.showPrevImage)
      }
    
      handleClickOutside(e) {
        if (e.target === e.currentTarget) {
          this.closeModal()
        }
      }
    
      handleKeyUp(event) {
        if (event.key === 'Escape') return this.closeModal()
        if (event.key === 'ArrowRight') return this.showNextImage()
        if (event.key === 'ArrowLeft') return this.showPrevImage()
      }
    
      showNextImage() {
        this.showImage(this.currentImage.nextElementSibling || this.DOM.galleryInner.firstElementChild)
      }
      showPrevImage() {
        this.showImage(this.currentImage.previousElementSibling || this.DOM.galleryInner.lastElementChild)
      }
    

    addEventListener() {
        this.DOM.images.forEach(image => {
            image.addEventListener('click', this.showImage.bind(this))

            image.addEventListener('keyup', (event) => {
                if (event.key === 'Enter') {
                    this.showImage.bind(this)
                }
            })
        })

        this.DOM.modal.addEventListener('click', this.handleClickOutside.bind(this))
    }

    init() {
        this.addEventListener()
     
    }
}