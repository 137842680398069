export default class ServicesSearch {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.tabs = [
      ...this.DOM.el.querySelectorAll(".js-service-services-dropdown"),
    ]
    this.DOM.buttons = [...document.querySelectorAll(".js-services-button")]
    this.DOM.masterTab = this.DOM.el.querySelector(
      ".js-master-services-dropdown"
    )
    this.currentTab
    this.init()
  }

  init() {
    this.addEventListeners()
  }

  showTabs(event) {
    // OBJECTS
    const button = event.currentTarget
    const currentTabSelector = button.dataset.button

    //remove active on all other buttons
    this.DOM.buttons.forEach((button) => {
      button.classList.remove("active")
    })

    window.scrollTo(0, 500)

    // find maching current dropdown menu
    this.DOM.tabs.forEach((tab) => {
      if (tab.dataset.button === currentTabSelector) {
        this.currentTab = tab
        return this.currentTab
      }
    })

    // MASTER TAB
    // Show if no tabs are active
    if (this.DOM.masterTab.classList.contains("js-active")) {
      // master tab is already open
    } else {
      // open master tab
      this.DOM.masterTab.style.height = `${this.currentTab.scrollHeight}px`
      this.DOM.masterTab.classList.add("js-active")
    }

    // if the current target already contains js-open remove master tab
    if (button.classList.contains("js-open")) {
      this.DOM.masterTab.classList.remove("js-active")
      this.DOM.masterTab.style.height = `${0}px`

      // remove old content
      this.DOM.tabs.forEach((tab) => {
        tab.classList.remove("active")
      })

      button.classList.remove("js-open")
    } else {
      // remove old content
      this.DOM.tabs.forEach((tab) => {
        tab.classList.remove("active")
      })

      // remove all viewed tabs
      this.DOM.buttons.forEach((button) => {
        button.classList.remove("js-open")
      })

      // load in new content
      button.classList.add("js-open")
      this.currentTab.classList.add("active")
      this.DOM.masterTab.style.height = `${this.currentTab.scrollHeight}px`
    }
  }

  onResize() {
    // if tab is open on resize, resize tab

    if (this.currentTab && this.DOM.masterTab.classList.contains("js-active")) {
      // due to positioning the master tab - 2rem we have to add 2 rem here
      this.DOM.masterTab.style.height = `calc(${this.currentTab.scrollHeight}px + 2rem )`
    }
  }

  addEventListeners() {
    this.DOM.buttons.forEach((button) => {
      button.addEventListener("click", this.showTabs.bind(this))
    })
  }
}
