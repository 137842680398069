// core version + navigation, pagination modules:
import Swiper, { Scrollbar, Autoplay } from "swiper"
// configure Swiper to use modules
Swiper.use([Scrollbar, Autoplay])

export default class textChild {
  constructor() {
    this.DOM = { el: document.querySelector(".js-text-child") }

    // Team Form
    this.DOM.teamSlider = this.DOM.el.querySelector(".js-team-swiper")
    this.DOM.teamSwiperItems = [
      ...this.DOM.el.querySelectorAll(".js-team-swiper-slide"),
    ]
    this.DOM.teamScrollbar = this.DOM.el.querySelector(
      ".js-team-swiper-scrollbar"
    )

    // post slider
    this.DOM.postSlider = this.DOM.el.querySelector(".js-post-slider")
    this.DOM.postSwiperItems = [
      ...this.DOM.el.querySelectorAll(".js-post-slider-slide"),
    ]
    this.DOM.postButtonNext = this.DOM.el.querySelector(".js-post-next")
    this.DOM.postButtonPrev = this.DOM.el.querySelector(".js-post-prev")

    this.init()
    this.addEventListeners()
  }

  init() {
    if (this.DOM.teamSwiperItems.length > 2) this.teamSwiperInit()
    this.postSwiperInit()
  }

  teamSwiperInit() {
    this.teamSwiper = new Swiper(this.DOM.teamSlider, {
      slidesPerView: 3,
      spaceBetween: 5,
      grabCursor: true,
      updateOnImagesReady: true,
      loop: true,
      scrollbar: {
        el: this.DOM.teamScrollbar,
        hide: false,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 1.75,
          spaceBetween: 15,
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        // when window width is >= 640px
        1920: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
    })
  }

  postSwiperInit() {
    console.log("postSwiperInit")

    console.log(this.DOM.postSlider)
    this.postSwiper = new Swiper(this.DOM.postSlider, {
      slidesPerView: 3,
      spaceBetween: 5,
      grabCursor: true,
      updateOnImagesReady: true,
      loop: true,
      navigation: {
        nextEl: this.DOM.postButtonNext,
        prevEl: this.DOM.postButtonPrev,
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 1.25,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        // when window width is >= 640px
        1920: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    })
  }

  addEventListeners() {}
}
