import gsap from "gsap"

export default class Header {
  constructor() {
    this.body = document.querySelector("body")
    this.main = document.querySelector("main")
    this.DOM = { el: document.querySelector("header") }
    this.DOM.headerType = this.DOM.el.querySelector(".header-type")
    this.DOM.toggleIcon = this.DOM.el.querySelector(".menu_toggle")
    this.DOM.menuButton = this.DOM.el.querySelector(".menu-button")
    this.DOM.logo = this.DOM.el.querySelector(".js-middle-logo")
    this.DOM.mobileMenu = this.DOM.el.querySelector(".mobile-sidebar")
    this.DOM.pageBackground = document.querySelector(".page-cover")
    this.DOM.fullScreenNav = this.DOM.el.querySelector(".fullscreen-nav-js")
    this.DOM.socialGroup = document.querySelector(".js-social-group")
    this.DOM.revealActive = this.DOM.el.classList.contains(
      "header__activate_scrolling"
    )
    this.DOM.menuItem = [
      // ...document.querySelectorAll('.menu-item-has-children a:not(.sub-menu a)')
      ...document.querySelectorAll(".fc_menu-item"),
    ]
    this.DOM.menuItemHasChildren = [
      ...document.querySelectorAll(".fc-menu-has-children"),
    ]
    this.DOM.subMenuOuter = [...document.querySelectorAll(".js-sub-menu")]
    this.DOM.subMenus = [...document.querySelectorAll(".sub-menu")]
    this.DOM.underlines = [...document.querySelectorAll(".fc-underline-inner")]
    this.DOM.subItems = [...document.querySelectorAll(".button-sub-menu-item")]
    this.DOM.menuFormat = document.querySelector(".js-inner-inner-menu")
    this.DOM.menuInner = document.querySelector(".js-inner-menu")
    this.screenLarge = window.matchMedia("(min-width: 992px)")
    this.DOM.modal = document.querySelector(".js-contact-pop-up")
    this.DOM.menuSize = document.querySelector(".js-menu-max-size")
    this.activeSubMenu = null

    gsap.set(this.DOM.modal, { yPercent: 20, autoAlpha: 0, xPercent: 0 })

    this.DOM.subMenuOuter.map(
      (menu) =>
        (menu.style.minHeight = `${
          this.DOM.menuInner.offsetHeight - this.DOM.menuItem[0].offsetHeight
        }px`)
    )

    // sub sub menus
    this.DOM.subSubMenus = [...document.querySelectorAll(".js-sub-sub-menu")]
    this.DOM.subSubMenuInners = [
      ...document.querySelectorAll(".js-sub-sub-menu-inner"),
    ]

    this.DOM.subSubItems = [
      ...document.querySelectorAll(".js-sub-sub-menu-item"),
    ]
    this.DOM.subSubToggles = [
      ...document.querySelectorAll(".js-sub-sub-menu-toggle"),
    ]

    this.DOM.subSubItems.map((item) => item.classList.add("js-hide"))
    this.currentSubMenu = null

    this.menuSize = 0
    this.scrollUp = "js-scroll-up"
    this.scrollDown = "js-scroll-down"
    this.lastScroll = 0
    this.windowX = null
    this.winY = null
    this.init()
  }

  toggleSubMenu(event) {
    const icon = event.currentTarget
    event.preventDefault()
    icon.disabled = true

    // get current submenu
    const currentMenu = icon.parentNode.parentNode
    const currentMenuItemHasChildren = [
      ...currentMenu.querySelectorAll(".js-sub-menu-has-children"),
    ]
    const currentSubMenu = currentMenu.querySelector(".fc_sub-menu")
    const currentSubItems = [
      ...currentSubMenu.querySelectorAll(".button-sub-menu-item"),
    ]
    const underline = currentMenu.querySelector(".fc-underline-inner")

    // find current menuitem
    const OLDmenus = this.DOM.menuItem.filter((item) => item !== currentMenu)

    // set menu heigth
    this.currentSubMenu = currentSubMenu

    if (icon.classList.contains("active-sub-menu")) {
      // transition back in

      this.tlIn = gsap.timeline()
      const reversesub = currentSubItems.reverse()

      // move nav back to original space
      if (this.DOM.heightTravel) {
        this.tlIn.to(currentMenu, {
          y: 0,
          ease: "expo.in",
          duration: 0.3,
        })
      }

      this.tlIn
        .to(reversesub, {
          yPercent: -10,
          autoAlpha: 0,
          ease: "expo.in",
          duration: 0.2,
          stagger: 0.05,
        })
        .to(OLDmenus, {
          xPercent: 0,
          ease: "expo.inOut",
          duration: 0.15,
          stagger: 0.05,
          onStart: () => {
            currentMenuItemHasChildren.map((item) =>
              item.classList.add("js-hide")
            )
          },
          onComplete: () => {
            icon.classList.remove("active-sub-menu")
            underline.classList.remove("active-underline")
            currentSubMenu.classList.remove("active-menu")
            this.currentSubMenu = null
            icon.disabled = false
            this.setMenuSize(this.DOM.menuFormat)
          },
        })
    } else {
      // first transition
      // transition out

      this.tlOut = gsap.timeline()

      const fromCurrentItemHeight = currentMenu.getBoundingClientRect().top
      const fromMenuInnerHeight = this.DOM.menuInner.getBoundingClientRect().top
      this.DOM.heightTravel = fromCurrentItemHeight - fromMenuInnerHeight

      this.tlOut
        .to(OLDmenus, {
          onStart: () => {
            icon.classList.add("active-sub-menu")
            underline.classList.add("active-underline")

            // close all sub sub menus
            this.DOM.subSubItems.map((item) => item.classList.add("js-hide"))
            this.DOM.subSubToggles.map((toggle) =>
              toggle.classList.remove("active-sub-menu")
            )
            this.DOM.subSubMenus.map(
              (menu) => (menu.style.minHeight = `${0}px`)
            )
            this.DOM.subSubMenuInners.map((inner) =>
              inner.classList.remove("js-open")
            )
          },
          onComplete: () => {
            this.activeSubMenu = currentSubMenu
            currentSubMenu.classList.add("active-menu")
            this.setMenuSize(this.currentSubMenu)

            setTimeout(() => {
              currentMenuItemHasChildren.map((item) => {
                item.classList.remove("js-hide")
              })
            }, 500)
          },
          xPercent: -100,
          ease: "expo.in",
          duration: 0.5,
          stagger: 0.05,
        }) // Move nav item up for more space
        .to(currentMenu, {
          y: -this.DOM.heightTravel,
          ease: "expo.inOut",
          duration: 0.15,
        })
        .to(currentSubItems, {
          yPercent: 0,
          ease: "expo.in",
          duration: 0.2,
          stagger: 0.1,
          autoAlpha: 1,

          onComplete: () => {
            icon.disabled = false
          },
        })
    }

    // expand selected menu item
    if (this.DOM.headerType.classList.contains("logo_left")) {
      // Logo Left
    } else if (this.DOM.headerType.classList.contains("logo_middle")) {
      if (Array.isArray(this.DOM.subMenus)) {
        // close all other subMenus when selecting a new submenu
        this.DOM.subMenus.forEach((menu) => {
          menu.classList.remove("active-menu")
        })

        //resize menu item
        this.closeDropdowns()

        // on fullscreen with multiple dropdowns choose selected dropdowns
        if (icon.classList.contains("open-menu")) {
          icon.classList.remove("open-menu")
        } else {
          icon.classList.add("open-menu")
        }
      }
    } else {
    }

    //  console.log(icon)

    //show or hide each menu
    if (icon.classList.contains("js-open")) {
      currentSubMenu.classList.remove("active-menu")
      icon.classList.remove("js-open")
      //   console.log('x')

      // on first click fullscreen nav menu with multiple submenus
    } else if (
      icon.classList.contains("open-menu") &&
      !icon.classList.contains("js-open")
    ) {
      icon.classList.add("js-open")
    } else if (
      !icon.classList.contains("js-open") &&
      this.DOM.headerType.classList.contains("logo_middle") &&
      Array.isArray(this.DOM.subMenus)
    ) {
      currentSubMenu.classList.remove("active-menu")
      icon.classList.remove("js-open")
    } else {
      currentSubMenu.classList.add("active-menu")
      this.activeSubMenu = currentSubMenu
      icon.classList.add("js-open")
    }
  }

  toggleSubSubMenu(event) {
    // set menu height
    this.setMenuSize(this.currentSubMenu)

    const icon = event.currentTarget
    event.preventDefault()
    icon.disabled = true

    // get current submenu
    const currentItem = icon.parentNode.parentNode
    const currentMenu = currentItem.querySelector(".js-sub-sub-menu")
    const currentMenuInner = currentItem.querySelector(".js-sub-sub-menu-inner")
    const currentSubSubItems = [
      ...currentMenu.querySelectorAll(".js-sub-sub-menu-item"),
    ]

    const currentSubItems = [
      ...this.activeSubMenu.querySelectorAll(".js-sub-menu-item"),
    ]

    this.tlSubOut = gsap.timeline()
    this.tlSubIn = gsap.timeline()

    //  Get non active sub sub items
    const notActiveSubMenus = currentSubItems.filter(
      (item) => item !== currentItem
    )

    const innerSubMenu = this.activeSubMenu.querySelector(".fc_sub-menu-inner")

    // TODO Get positions of current sub menu
    const fromCurrentItemHeight = currentItem.getBoundingClientRect().top
    const fromMenuInnerHeight = innerSubMenu.getBoundingClientRect().top
    this.DOM.subMenuheightTravel = fromCurrentItemHeight - fromMenuInnerHeight

    if (icon.classList.contains("active-sub-menu")) {
      // CLOSE
      // Animate in view the other sub sub menus
      // Move subsub menu back to original sub menu position

      this.tlSubIn
        .to(currentItem, {
          y: 0,
          ease: "expo.in",
          duration: 0.3,
        })
        .to(notActiveSubMenus, {
          xPercent: 0,
          ease: "expo.inOut",
          duration: 0.25,
          stagger: 0.05,
          onStart: () => {
            icon.classList.remove("active-sub-menu")
            currentSubSubItems.reverse().forEach((item, i) => {
              setTimeout(() => {
                item.classList.add("js-hide")
              }, i * 35)
            })
          },
          onComplete: () => {
            currentMenu.style.height = `${0}px`
            currentMenuInner.classList.remove("js-open")
            icon.disabled = false
            this.setMenuSize(this.currentSubMenu)
            // scroll to top of sub menu
            this.DOM.menuInner.scrollTop = 0
          },
        })
    } else {
      // OPEN
      // Animate out of view the other sub sub menus
      // Move subsub menu to top of active sub menu

      this.tlSubOut
        .to(notActiveSubMenus, {
          onStart: () => {
            icon.classList.add("active-sub-menu")
            icon.disabled = false
            // underline.classList.add("active-underline")
            // close all sub sub menus
            currentSubSubItems.forEach((item, i) => {
              setTimeout(() => {
                item.classList.remove("js-hide")
              }, i * 100)
            })

            currentMenuInner.classList.add("js-open")

            currentMenu.style.height = `${
              currentMenuInner.getBoundingClientRect().height
            }px`

            this.setMenuSize(currentItem)
          },
          onComplete: () => {},
          xPercent: -110,
          ease: "expo.in",
          duration: 0.5,
          stagger: 0.05,
        }) // Move nav item up for more space
        .to(currentItem, {
          y: -this.DOM.subMenuheightTravel,
          ease: "expo.inOut",
          duration: 0.15,
        })
    }
  }

  init() {
    this.setheaderTopBarSize()
    this.setMenuSize(this.DOM.menuFormat)
    this.eventListeners()
    gsap.set(this.DOM.underlines, { xPercent: -101 })
    gsap.set(this.DOM.subItems, { yPercent: -10, autoAlpha: 0 })

    if (document.querySelector(".HeroFullWidth")) {
      this.DOM.socialGroup.classList.add("logo_middle_socials--right")
    }
  }

  setheaderTopBarSize() {
    if (this.main) this.main.style.paddingTop = `${this.DOM.el.offsetHeight}px`
  }

  scrollMenu(e) {
    this.DOM.menuInner.scrollTop += e.deltaY
  }

  setMenuSize(innerMenu) {
    if (!innerMenu) return

    // method for scrolling the menu if it is larger than the menu section
    this.scrollMenuEvent = this.DOM.menuInner.addEventListener(
      "mousewheel",
      (e) => this.scrollMenu(e)
    )

    this.menuSize = this.DOM.menuSize.offsetHeight

    if (innerMenu.offsetHeight > this.menuSize) {
      this.DOM.menuInner.classList.add("js-scroll")

      this.DOM.menuInner.addEventListener("mouseenter", this.scrollMenuEvent)
      this.DOM.menuInner.removeEventListener("mouseleave", this.scrollMenuEvent)

      // using js focus element and allow to scroll on hover
    } else {
      this.DOM.menuInner.classList.remove("js-scroll")
      this.DOM.menuInner.removeEventListener("mouseenter", this.scrollMenuEvent)
    }
  }

  resizeEvent() {
    this.DOM.subMenuOuter.map(
      (menu) =>
        (menu.style.minHeight = `${
          this.DOM.menuInner.offsetHeight - this.DOM.menuItem[0].offsetHeight
        }px`)
    )

    this.setMenuSize(this.DOM.menuFormat)
    this.setheaderTopBarSize()

    if (this.currentSubMenu !== null) this.setMenuSize(this.currentSubMenu)
  }

  loadInSubMenu(event) {
    // animate underline
    const underline = event.target.querySelector(".fc-underline-inner")
    gsap.to(underline, {
      xPercent: 0,
      ease: "expo",
      duration: 0.8,
    })
    // load in sub menu if there is one
  }

  loadOutSubMenu(event) {
    // animate underline
    const underline = event.target.querySelector(".fc-underline-inner")
    gsap.to(underline, {
      xPercent: -101,
      ease: "expo",
      duration: 0.8,
    })
    // load in sub menu if there is one
  }

  eventListeners() {
    this.DOM.menuItem.forEach((item) => {
      item.addEventListener("mouseenter", this.loadInSubMenu.bind(this))
      item.addEventListener("mouseleave", this.loadOutSubMenu.bind(this))
    })

    if (this.DOM.menuButton) {
      this.DOM.menuButton.addEventListener("click", this.toggleMenu.bind(this))
    }

    this.DOM.subSubToggles.forEach((button) => {
      button.addEventListener("click", this.toggleSubSubMenu.bind(this))
    })

    window.addEventListener("resize", this.resizeEvent.bind(this))

    this.DOM.menuItemHasChildren.forEach((button) => {
      const circle = button.querySelector(".fc-round-outer-plus")
      circle.addEventListener("click", this.toggleSubMenu.bind(this))
    })
  }

  toggleMenu() {
    this.DOM.toggleIcon.classList.toggle("toggle_on")

    // animate out modal
    if (this.DOM.modal.classList.contains("visible")) {
      // animate out
      gsap.to(this.DOM.modal, {
        yPercent: 20,
        ease: "expo.in",
        duration: 0.4,
        autoAlpha: 0,
        onComplete: () => {
          this.DOM.modal.classList.remove("visible")
          this.DOM.pageBackground.classList.remove("page-cover-opacity")
          window.lenis ? window.lenis.start() : ""
        },
      })
    }

    // IF we have logo - left header selected
    if (this.DOM.mobileMenu) {
      this.DOM.mobileMenu.classList.toggle("mobile-menu-appear")
      this.DOM.pageBackground.classList.toggle("page-cover-opacity")
      //   this.DOM.page.classList.toggle('paged');
    }
    if (this.DOM.headerType.classList.contains("logo_left")) return
    // IF we have logo middle selected
    // This will work if scrolling header is remvoed otherwise please check below in scorlling function
    if (this.DOM.fullScreenNav.classList.contains("opacity-full")) {
      this.DOM.fullScreenNav.classList.remove("opacity-full")
      // Remove listener to re-enable scroll
      this.body.style.overflow = "visible"
    } else {
      this.DOM.fullScreenNav.classList.add("opacity-full")
      // disable scroll when fullscreen nav is visible
      this.body.style.overflow = "hidden"
    }
  }

  closeDropdowns() {
    const dropdwns = [...document.querySelectorAll(".sm-dropdown")]
    dropdwns.forEach((toggle) => toggle.classList.remove("js-open"))
  }

  scrolling(e) {
    this.header.currentScroll = window.pageYOffset

    this.header.lastScroll = this.header.currentScroll
  }
}
